.App {
  text-align: center;
}

.App-section {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}



#html5qr-code-full-region {
  background-color: white;
  color: #0445c7;
  font-size: 14pt;
}

@media(max-width: 640px) {
	#html5qr-code-full-region {
		width: 300px;
    height: 200px;
	}
}

@media(min-width: 640px) {
	#html5qr-code-full-region {
		width: 600px;
    height: 200px;
	}
  #html5qr-code-full-region__scan_region {
    height: 200px;
  }
  #html5qr-code-full-region__scan_region video{
    max-height: 200px;
    width:auto;
    height: 100%;
  }
}

thead th {
  background-color: #1976D2;
}

button {
  background-color: #1976D2;
  color: white;
  border-radius: 20;
  border: none;
}

.error {
  color: red;
}